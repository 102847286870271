import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import { Form, Input, Button, Divider, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FiKey } from 'react-icons/fi';

import LayoutHeader from '../blocks/codes/authPageHeader';
import { Firebase } from '../config/firebase';
import SEO from '../blocks/layout/seo';
import '../styles/auth-page.scss';

// markup
const SignupPage = () => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false);
      const [googleLoading, setGoogleLoading] = useState(false);
      const [, forceUpdate] = useState({});

      useEffect(() => {
            forceUpdate({});
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        navigate('/setup');
                  }
            });
      }, []);

      const signupWithEmail = (values) => {
            const { email, password } = values;
            setLoading(true);
            Firebase.auth()
                  .createUserWithEmailAndPassword(email, password)
                  .then((result) => {
                        // navigate('/dashboard')
                        form.resetFields();
                        message.success('You are registered successfully!');
                        setLoading(false);
                        navigate('/dashboard');
                  })
                  .catch((err) => {
                        if (err.code === 'auth/email-already-in-use') {
                              message.info(
                                    'The email address is already in use by another account'
                              );
                        } else {
                              message.error(err.message);
                        }
                        setLoading(false);
                        form.resetFields();
                  });
      };

      const loginWithGoogle = (e) => {
            e.preventDefault();
            setGoogleLoading(true);
            const providerOAuth = new Firebase.auth.GoogleAuthProvider();

            Firebase.auth()
                  .signInWithPopup(providerOAuth)
                  .then((result) => {
                        setGoogleLoading(false);
                  })
                  .catch((err) => {
                        setGoogleLoading(false);
                        if (
                              err.code ===
                              'auth/account-exists-with-different-credential'
                        ) {
                              message.info('Try to use email-password login');
                        } else {
                              message.error(err.message);
                        }
                  });
      };

      return (
            <div className="auth-page">
                  <SEO title="Create an account — NoCodeAPI" />
                  <LayoutHeader type="signup" />
                  <div className="auth-form">
                        <div className="heading">
                              <h2>Create an account</h2>
                              <p>
                                    By signing up, I agree to{' '}
                                    <a
                                          href="https://nocodeapi.com/terms"
                                          target="_blank"
                                    >
                                          Terms
                                    </a>{' '}
                                    and{' '}
                                    <a
                                          href="https://nocodeapi.com/privacy-policy"
                                          target="_blank"
                                    >
                                          Privacy Policy
                                    </a>{' '}
                                    of NoCodeAPI
                              </p>
                              <Button
                                    size="large"
                                    style={{
                                          padding: '0px 30px',
                                          width: 300,
                                          fontSize: 15,
                                          marginTop: 40,
                                    }}
                                    loading={googleLoading}
                                    onClick={loginWithGoogle}
                              >
                                    <img
                                          src="/google-logo.png"
                                          alt="google login"
                                          height="28px"
                                          width="28px"
                                          style={{
                                                marginRight: 15,
                                          }}
                                    />
                                    <span>Sign up with Google</span>
                              </Button>
                              <Divider
                                    plain
                                    style={{
                                          margin: '40px 0px',
                                          fontWeight: 300,
                                          fontSize: 13,
                                          color: '#606060',
                                    }}
                              >
                                    or sign up with email
                              </Divider>

                              <Form
                                    form={form}
                                    name="signup_form"
                                    className="auth-input-form"
                                    initialValues={{ remember: true }}
                                    onFinish={signupWithEmail}
                              >
                                    <Form.Item name="email">
                                          <Input
                                                size="large"
                                                prefix={
                                                      <MailOutlined className="site-form-item-icon" />
                                                }
                                                placeholder="Email Address"
                                                required
                                          />
                                    </Form.Item>
                                    <Form.Item name="password">
                                          <Input.Password
                                                prefix={
                                                      <FiKey className="site-form-item-icon" />
                                                }
                                                size="large"
                                                type="password"
                                                placeholder="Password"
                                                required
                                          />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                          {() => (
                                                <Button
                                                      type="primary"
                                                      size="large"
                                                      htmlType="submit"
                                                      loading={loading}
                                                      className="auth-btn auth-form-button"
                                                >
                                                      Create Free Account
                                                </Button>
                                          )}
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </div>
      );
};

export default SignupPage;
